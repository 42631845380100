import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { defineCustomElements } from '@duetds/date-picker/custom-element';
import '@kili-technology/theme';
import { HandClass } from '@kili-technology/cursors';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { OpenFeatureProvider } from '@openfeature/react-sdk';
import { enableMapSet } from 'immer';
import _get from 'lodash/get';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';

import { Auth0Provider, useAuth0 } from './auth0';
import AuthorizedApolloProvider from './components/AuthorizedApolloProvider';
import DependenciesProvider from './components/DependenciesProvider';
import ErrorBoundary from './components/ErrorBoundary';
import Loader from './components/Loader';
import Notifier from './components/Notifier';
import VersionReloadDialog from './components/VersionReloadDialog';
import {
  audience,
  clientId,
  domain,
  environment,
  googleTagManagerAuth,
  googleTagManagerId,
  googleTagManagerPreview,
  isUsingDatadog,
  isUsingGTM,
  isUsingSegment,
  onCypress,
  version,
} from './config';
import { ctrlOrMetaKey } from './constants/shortcuts';
import { useUserEmail } from './hooks/useUserEmail';
import './i18n/i18n';
import RootModule from './pages/RootModule';
import appHistory from './pages/RootModule/history';
import { initializeSegment } from './segmentAnalytics';
import { persistor, store } from './store';
import { getTheme } from './theme';
import { useStore } from './zustand';

enableMapSet();

defineCustomElements();

if (isUsingSegment()) {
  initializeSegment();
  const writeKey = _get(window, '_env_.ANALYTICS__SEGMENT_WRITE_KEY');
  const { analytics } = window;
  analytics?.load?.(writeKey);
}

if (isUsingGTM()) {
  const tagManagerArgs = {
    auth: googleTagManagerAuth,
    gtmId: googleTagManagerId,
    preview: googleTagManagerPreview,
  };
  TagManager.initialize(tagManagerArgs);
}

if (_get(window, '_env_.ENVIRONMENT') === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

// Remove default Chrome shortcut: https://stackoverflow.com/questions/15911785/overriding-shortcut-keys-in-firefox-and-chrome
// eslint-disable-next-line func-names
onkeydown = function (event) {
  if (ctrlOrMetaKey(event) && event.key === 'e') {
    event.preventDefault();
  }
};

if (isUsingDatadog()) {
  const payload = {
    actionNameAttribute: 'data-cy',
    // eslint-disable-next-line no-useless-escape
    allowedTracingOrigins: [/https\:\/\/.*cloud\.kili-technology\.com\/api\/.*/],
    applicationId: _get(window, '_env_.ANALYTICS__DATADOG_APPLICATION_ID'),
    clientToken: _get(window, '_env_.ANALYTICS__DATADOG_CLIENT_TOKEN'),
    enableExperimentalFeatures: ['feature_flags'],
    env: environment,
    forwardErrorsToLogs: false,
    'release-version': version,
    sampleRate: 100,
    service: 'label-frontend',
    site: _get(window, '_env_.ANALYTICS__DATADOG_SITE'),
    trackFrustrations: true,
    trackInteractions: true,
    version,
  };
  datadogRum.init(payload);
  datadogLogs.init(payload);
}

const onRedirectCallback = appState => {
  appHistory.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const App = () => {
  const { isInitializing } = useAuth0();
  const userEmail = useUserEmail();

  useEffect(() => {
    document.body.classList.add(HandClass);

    return () => {
      document.body.classList.remove(HandClass);
    };
  }, []);

  if (isInitializing) {
    return <Loader />;
  }

  if (isUsingDatadog()) {
    datadogRum.setUser({
      email: userEmail,
    });
  }
  const theme = getTheme();

  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AuthorizedApolloProvider>
          <OpenFeatureProvider>
            <ErrorBoundary>
              <DependenciesProvider>
                <RootModule />
                <Notifier />
                <VersionReloadDialog />
              </DependenciesProvider>
            </ErrorBoundary>
          </OpenFeatureProvider>
        </AuthorizedApolloProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};

const urlSearchParams = new URLSearchParams(window.location.search);
const needsToSignUp = urlSearchParams.get('signup');
const email = urlSearchParams.get('email');

const render = () => {
  ReactDOM.render(
    <Auth0Provider
      audience={audience}
      client_id={clientId}
      domain={domain}
      login_hint={email || undefined}
      onRedirectCallback={onRedirectCallback}
      redirect_uri={`${window.location.origin}/label/`}
      screen_hint={needsToSignUp ? 'signup' : 'signin'}
    >
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProvider maxSnack={3} preventDuplicate>
            <App />
          </SnackbarProvider>
        </PersistGate>
      </Provider>
    </Auth0Provider>,
    document.getElementById('root'),
  );
};

render();

// Expose store during tests
if (onCypress) {
  window.store = store;
  window.zustandStore = useStore;
}
