import { saveAs } from 'file-saver';

import { getBackendUrl } from '@/config';
import { isUrl } from '@/helpers/url';

import { NUMBER_OF_RETRY, DELAY_BETWEEN_RETRY, BLACK_LISTED_PARAMETERS } from './constants';

const urlMatchesFromMultiFrontend = (url: string): boolean => {
  const multiFrontendRegexPattern =
    /^https:\/\/multifrontend-[\w-]+\.staging\.cloud\.kili-technology\.com/;

  if (multiFrontendRegexPattern.test(window.location.origin)) {
    const stagingFrontendRegex = /^https:\/\/staging\.cloud\.kili-technology\.com/;
    return stagingFrontendRegex.test(url);
  }
  return false;
};

export const isAssetServedByKili = (url: string): boolean =>
  !!url && (url.startsWith(getBackendUrl()) || urlMatchesFromMultiFrontend(url));

const getResponseByFetchingReadSignedUrl = async (
  url: string,
  headers: Headers,
  abortSignal?: AbortSignal,
): Promise<Response> => {
  const assetUrl = new URL(url);
  assetUrl.searchParams.set('bucketsigned', 'true');
  const dataWithReadUrlResponse = await fetch(assetUrl.href, {
    headers,
    method: 'GET',
  });

  if (!dataWithReadUrlResponse.ok) {
    const { status, statusText } = dataWithReadUrlResponse;
    throw new Error(`Request to retrieve asset URL failed with status ${status} ${statusText}`);
  }

  const readUrl = await dataWithReadUrlResponse.json();

  return fetch(readUrl, {
    headers: isAssetServedByKili(readUrl) ? headers : undefined,
    method: 'GET',
    signal: abortSignal,
  });
};

export const kiliFetch = async ({
  url,
  authorization,
  abortSignal,
  cacheKey = '',
  cacheName = 'assets',
}: {
  abortSignal?: AbortSignal;
  authorization: string | null;
  cacheKey?: string;
  cacheName?: string;
  url: string;
}): Promise<Response> => {
  const headers = new Headers({
    Accept: 'application/json',
  });
  const assetIsServedByKili = isAssetServedByKili(url);

  if (assetIsServedByKili && authorization) {
    headers.set('Authorization', authorization);
  }

  let cachedResponse = await window.caches?.match?.(url, { cacheName })?.catch(() => null);
  if (!cachedResponse) {
    cachedResponse = await window.caches?.match?.(cacheKey, { cacheName })?.catch(() => null);
  }

  if (cachedResponse) {
    // This handles old cache that could be set to proxified url
    if (cacheKey && window.caches && url !== cacheKey) {
      const cachedClone = cachedResponse.clone();
      window.caches
        .open(cacheName)
        .then(cacheEl => {
          return cacheEl
            .put(cacheKey, cachedClone)
            .then(() => cacheEl.delete(url))
            .catch(() => null);
        })
        .catch(() => null);
    }
    return cachedResponse;
  }

  let response: Response;
  if (assetIsServedByKili) {
    response = await getResponseByFetchingReadSignedUrl(url, headers, abortSignal);
  } else {
    response = await fetch(url, {
      headers,
      method: 'GET',
      signal: abortSignal,
    });
  }

  if (!response.ok) {
    const { status, statusText } = response;
    throw new Error(`Request failed with status ${status} ${statusText}`);
  }

  if (cacheKey && window.caches) {
    const responseClone = response.clone();
    window.caches
      .open(cacheName)
      .then(assets => assets.put(cacheKey, responseClone))
      .catch(() => null);
  }

  return response;
};

const urlToCacheKey = (stringedUrl: string) => {
  const url = new URL(stringedUrl);
  BLACK_LISTED_PARAMETERS.forEach(key => url.searchParams.delete(key));
  const cacheKey = url.href;
  return cacheKey;
};

export const downloadAssetContent = async (
  assetContent: string,
  authenticationToken: string | null,
  abortSignal?: AbortSignal,
  shouldAppendToCache = true,
  cacheName = 'assets',
): Promise<Response> => {
  if (!isUrl(assetContent)) {
    throw new Error(`Invalid asset content, must be an URL: ${assetContent}.`);
  }
  return kiliFetch({
    abortSignal,
    authorization: authenticationToken,
    cacheKey: shouldAppendToCache ? urlToCacheKey(assetContent) : '',
    cacheName,
    url: assetContent,
  });
};

export const downloadPdfAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  abortSignal?: AbortSignal,
  shouldAppendToCache = true,
): Promise<string> =>
  downloadAssetContent(assetContent, authenticationToken, abortSignal, shouldAppendToCache).then(
    response => response.text(),
  );

export const downloadTextAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  abortSignal?: AbortSignal,
  shouldAppendToCache = true,
): Promise<string> =>
  downloadAssetContent(assetContent, authenticationToken, abortSignal, shouldAppendToCache).then(
    response => response.text(),
  );

export const downloadImageAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  abortSignal?: AbortSignal,
  shouldAppendToCache = true,
  cacheName = 'assets',
): Promise<string> => {
  const response = await downloadAssetContent(
    assetContent,
    authenticationToken,
    abortSignal,
    shouldAppendToCache,
    cacheName,
  );
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

export const downloadVideoAsset = async (
  assetContent: string,
  authenticationToken: string | null,
  abortSignal?: AbortSignal,
  shouldAppendToCache = true,
): Promise<string> =>
  downloadAssetContent(assetContent, authenticationToken, abortSignal, shouldAppendToCache)
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob));

export const downloadRetry = async (
  url: string,
  authenticationToken: string | null,
  abortSignal: AbortSignal,
  numberOfRetry: number,
  delay: number,
): Promise<Blob | undefined> => {
  try {
    const asset = await downloadAssetContent(url, authenticationToken, abortSignal).then(response =>
      response?.blob(),
    );
    return asset;
  } catch (err) {
    if (numberOfRetry === 1) throw err;
    await new Promise(resolve => {
      setTimeout(resolve, delay);
    });
    return downloadRetry(url, authenticationToken, abortSignal, numberOfRetry - 1, delay);
  }
};

export const addUrlToCache =
  (imageCache: Cache, authenticationToken: string | null, abortSignal: AbortSignal) =>
  async (url: string): Promise<void> => {
    const request = new Request(url, {
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(authenticationToken ? { authorization: authenticationToken } : {}),
      }),
    });
    try {
      await downloadRetry(
        url,
        authenticationToken,
        abortSignal,
        NUMBER_OF_RETRY,
        DELAY_BETWEEN_RETRY,
      ).then(blob => {
        const imageResponse = new Response(blob);
        if (imageCache.put) imageCache.put(request, imageResponse).catch(() => null);
      });
    } catch (err) {
      if (err instanceof Error && err.name !== 'AbortError') throw err;
    }
  };

export const downloadBucketSignedExportUrl = async (url: string) => {
  const encodedFilename = new URL(url).pathname.split('/').at(-1);
  const filename = encodedFilename ? decodeURIComponent(encodedFilename) : undefined;
  const response = await fetch(url);
  const blob = await response.blob();

  saveAs(blob, filename);
};
