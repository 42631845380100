import {
  Labeled as LabeledIcon,
  Ongoing as OngoingIcon,
  Review as ToReviewIcon,
  Reviewed as ReviewedIcon,
  Todo as TodoIcon,
  Unresolve as Skipped,
} from '@kili-technology/icons';
import { t } from 'i18next';

import { StepStatus, type StepType, type AssetWorkflowStep } from '@/__generated__/globalTypes';
import IconLabel from '@/pages/project/explore/components/AssetAccordion/IconLabel/IconLabel';

type StepStatusProps = {
  currentStep: AssetWorkflowStep;
};

export const REVIEW_STATUS_STEP_TO_DISPLAY = {
  [StepStatus.DONE]: t('domain.asset.statuses.review.done'),
  [StepStatus.IN_PROGRESS]: t('domain.asset.statuses.review.inProgress'),
  [StepStatus.REWORK]: t('domain.asset.statuses.review.rework'),
  [StepStatus.PARTIALLY_DONE]: t('domain.asset.statuses.review.partiallyDone'),
  [StepStatus.SKIPPED]: t('domain.asset.statuses.review.skipped'),
  [StepStatus.TO_DO]: t('domain.asset.statuses.review.toDo'),
};

export const DEFAULT_STATUS_STEP_TO_DISPLAY = {
  [StepStatus.DONE]: t('domain.asset.statuses.label.done'),
  [StepStatus.IN_PROGRESS]: t('domain.asset.statuses.label.inProgress'),
  [StepStatus.REWORK]: t('domain.asset.statuses.label.rework'),
  [StepStatus.PARTIALLY_DONE]: t('domain.asset.statuses.label.partiallyDone'),
  [StepStatus.SKIPPED]: t('domain.asset.statuses.label.skipped'),
  [StepStatus.TO_DO]: t('domain.asset.statuses.label.toDo'),
};

export const STATUS_STEP_ICON = (type: StepType) => {
  const isDefault = type === 'DEFAULT';
  if (isDefault) {
    return {
      [StepStatus.DONE]: () => <LabeledIcon fill="var(--color-epsilon-accent-5)" />,
      [StepStatus.IN_PROGRESS]: () => <OngoingIcon fill="var(--color-gamma-accent-3)" />,
      [StepStatus.REWORK]: () => <OngoingIcon fill="var(--color-gamma-accent-3)" />,
      [StepStatus.PARTIALLY_DONE]: () => <OngoingIcon fill="var(--color-gamma-accent-3)" />,
      [StepStatus.SKIPPED]: () => <Skipped fill="var(--color-omega-accent-5)" />,
      [StepStatus.TO_DO]: () => <TodoIcon fill="var(--color-omega-accent-5)" />,
    };
  }
  return {
    [StepStatus.DONE]: () => (
      <ReviewedIcon
        fill={isDefault ? 'var(--color-epsilon-accent-5)' : 'var(--color-alpha-accent-9)'}
      />
    ),
    [StepStatus.IN_PROGRESS]: () => <ToReviewIcon fill="var(--color-zeta-accent-3)" />,
    [StepStatus.REWORK]: () => <ToReviewIcon fill="var(--color-zeta-accent-3)" />,
    [StepStatus.PARTIALLY_DONE]: () => <ToReviewIcon fill="var(--color-zeta-accent-3)" />,
    [StepStatus.SKIPPED]: () => <Skipped fill="var(--color-omega-accent-5)" />,
    [StepStatus.TO_DO]: () => <LabeledIcon fill="var(--color-omega-accent-5)" />,
  };
};

export const StepStatusIcon = ({ currentStep }: StepStatusProps) => {
  const { type, status, name } = currentStep;
  let title: string;
  if (type === 'REVIEW') {
    title = name.replace('Reviewing', REVIEW_STATUS_STEP_TO_DISPLAY[status]);
  } else {
    title = name.replace('Labeling', DEFAULT_STATUS_STEP_TO_DISPLAY[status]);
  }

  return (
    <div data-cy="status" data-value={title}>
      <IconLabel icon={STATUS_STEP_ICON(type)[status]} text={title} />
    </div>
  );
};
