import { gql } from '@apollo/client';

import {
  PROJECT_FRAGMENTS_WITHOUT_KPIS,
  PROJECT_FRAGMENTS_ISSUE_STATISTICS,
  PROJECT_FRAGMENTS_QUALITY_SETTINGS,
  PROJECT_FRAGMENTS_INTERFACE_SETTINGS,
} from './fragments';

export const GQL_PROJECT = gql`
  query GetProject($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      author {
        firstname
        lastname
        email
        organization {
          id
          name
          license {
            type
          }
        }
      }
      description
      inputType
      instructions
      isAnonymized
      jsonInterface
      rights
      title
    }
  }
`;

export const GQL_PROJECT_INTERFACE_SETTINGS = gql`
  query GetProjectInterfaceSettings($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      ...ProjectInfosForInterfaceSettings
    }
  }
  ${PROJECT_FRAGMENTS_INTERFACE_SETTINGS}
`;

export const GQL_PROJECT_QUALITY_SETTINGS = gql`
  query GetProjectForQualitySettings($projectWhere: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $projectWhere, first: $first, skip: $skip) {
      ...ProjectInfosForQualitySettings
    }
  }
  ${PROJECT_FRAGMENTS_QUALITY_SETTINGS}
`;

export const GQL_PROJECT_NO_KPIS_AND_PROJECT_USER_GET = gql`
  query ProjectNoKpisAndProjectUser(
    $projectWhere: ProjectWhere!
    $projectUserWhere: ProjectUserWhere!
    $first: PageSize!
    $skip: Int!
  ) {
    projects(where: $projectWhere, first: $first, skip: $skip) {
      ...ProjectInfosNoKpis
    }
    projectUsers(where: $projectUserWhere, first: $first, skip: $skip) {
      id
      activated
      role
      user {
        id
      }
    }
  }
  ${PROJECT_FRAGMENTS_WITHOUT_KPIS}
`;

export const GQL_PROJECT_ASSET_METADATA = gql`
  query GetProjectAssetMetadata($projectWhere: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $projectWhere, first: $first, skip: $skip) {
      assetMetadata
    }
  }
`;

export const GQL_PROJECT_ISSUES_STATISTICS = gql`
  query GetProjectIssuesStatistics($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      ...ProjectIssuesStatistics
    }
  }
  ${PROJECT_FRAGMENTS_ISSUE_STATISTICS}
`;

export const GQL_PROJECT_EXPORT_DATA = gql`
  query ExportData(
    $where: ProjectWhere!
    $exportType: ExportType
    $includeSentBackLabels: Boolean
    $labelFormat: LabelFormat
    $splitOption: SplitOption
    $versionName: String
  ) {
    data: exportData(
      where: $where
      exportType: $exportType
      includeSentBackLabels: $includeSentBackLabels
      labelFormat: $labelFormat
      splitOption: $splitOption
      versionName: $versionName
    )
  }
`;

export const GQL_PROJECT_RIGHTS = gql`
  query GetProjectRights($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      rights
    }
  }
`;

export const GQL_PROJECT_MAX_DATASET_SIZE = gql`
  query MaxDatasetSize($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      maxDatasetSize
    }
  }
`;

export const GQL_PROJECT_MIN_MAX_PRIORITY = gql`
  query GetProjectPriorities($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      minAndMaxPriority
    }
  }
`;

export const GQL_GET_QUEUE_STATUS = gql`
  query GetQueueStatus($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      queueStatus
    }
  }
`;

export const GQL_HAS_CUSTOM_PLUGINS = gql`
  query hasCustomPlugins($where: ProjectWhere!, $first: PageSize!, $skip: Int!) {
    projects(where: $where, first: $first, skip: $skip) {
      id
      hasCustomPlugins
    }
  }
`;
