import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { isUsingAuth0, useAuth0, ORIGINAL_PATH_KEY, ORIGINAL_SEARCH_KEY } from './auth0';
import appHistory from './pages/RootModule/history';
import { authenticationIsAuthenticated } from './redux/authentication/selectors';

export const useOriginalUrlRedirector = () => {
  const { isAuthenticated: auth0IsAuthenticated } = useAuth0();
  const kiliIsAuthenticated = useSelector(authenticationIsAuthenticated);
  const isAuthenticated = isUsingAuth0 ? auth0IsAuthenticated : kiliIsAuthenticated;
  const originalPath = sessionStorage.getItem(ORIGINAL_PATH_KEY);
  const originalSearch = sessionStorage.getItem(ORIGINAL_SEARCH_KEY);
  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname !== originalPath && originalPath) {
      const pathWithoutLabel = originalPath?.slice(7);
      const path = `${pathWithoutLabel}${originalSearch}`;
      if (path && isAuthenticated) {
        sessionStorage.removeItem(ORIGINAL_PATH_KEY);
        sessionStorage.removeItem(ORIGINAL_SEARCH_KEY);
        appHistory.push(path);
      }
    }
  }, [isAuthenticated, originalPath, originalSearch]);
};
