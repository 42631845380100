import { gql } from '@apollo/client';

export const GQL_IMPORT_CONVERSATIONS = gql`
  mutation ImportConversations($data: ImportConversationsData!, $where: ProjectWhere!) {
    importConversations(data: $data, where: $where) {
      numberOfUploadedAssets
      warnings {
        type
        details
        externalId
        lineNumber
      }
    }
  }
`;

export const GQL_CREATE_CHAT_ITEM = gql`
  mutation CreateChatItem($data: CreateChatItemData!, $where: AssetWhere!) {
    createChatItem(data: $data, where: $where) {
      content
      id
      labelId
      modelName
      modelId
      parentId
      role
    }
  }
`;

export const GQL_UPDATE_CHAT_ITEM = gql`
  mutation UpdateChatItem($data: UpdateChatItemData!, $where: ChatItemWhere!) {
    updateChatItem(data: $data, where: $where) {
      content
      id
      labelId
      modelId
      modelName
      parentId
      role
    }
  }
`;

export const GQL_REGENERATE_CHAT_ITEMS = gql`
  mutation RegenerateChatItems($where: ChatItemWhere!) {
    regenerateChatItems(where: $where) {
      content
      id
      labelId
      modelId
      modelName
      parentId
      role
    }
  }
`;

export const GQL_DELETE_CHAT_ITEM = gql`
  mutation DeleteChatItem($where: ChatItemWhere!) {
    deleteChatItem(where: $where)
  }
`;
